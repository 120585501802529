import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { breakpoints, colors, borders } from '../../theme'
import {
  Button,
  DatePicker,
  StyledButton,
  StyledDatePicker,
  Pulse,
} from '@monbanquet/crumble'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { EventContext } from '../../components/context/EventContext'
import { navigate } from '../../components/Link'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'
import dayjs from 'dayjs'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'
import { excludeDates } from '../../util/date'

const schema = yup.object().shape({
  startDateTime: yup
    .date()
    .nullable()
    .required(),
  endDateTime: yup
    .date()
    .nullable()
    .required(),
})

const getSuggestedHoursByCategory = categories => {
  return categories.reduce((map, category) => {
    if (category.suggestedHours === '' || !category.suggestedHours) {
      return map
    }

    const [suggestedStartHour] = category.suggestedHours.split(' - ')

    const [startHours, startMinuts] = suggestedStartHour.split('h')

    map[category.value] = {
      suggestedStart: {
        hours: parseInt(startHours, 10),
        minuts: parseInt(startMinuts, 10),
      },
    }
    return map
  }, {})
}

const DatePage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    state: { startDateTime, category, endDateTime },
    categories,
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const noValidate = useNoValidate()

  const submit = () => {
    navigate(computeNextPageWithBaseUri('demande', 'date'), {
      state: { next: true },
    })
  }

  const { formal, startDateTimeProps } = useFormalWithProps(
    {
      startDateTime,
      endDateTime,
    },
    {
      schema,
      onSubmit: submit,
    },
  )
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)
  const [wrongDateTime, setWrongDateTime] = useState(false)

  const suggestedHoursByCategory = getSuggestedHoursByCategory(categories)

  return (
    <StyledDatePage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`À quelle date aura lieu votre événement\u00A0?`}
              subTitle={
                <p className={`hint ${wrongDateTime ? 'wrong' : ''}`}>
                  {`Indiquez une estimation si vous n'avez pas encore fixé de date`}
                </p>
              }
            />

            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err)
                  setWrongDateTime(true)
                  setTimeout(() => setWrongDateTime(false), 800)
                }
              }}
            >
              <DatePicker
                required
                {...startDateTimeProps}
                excludeDates={excludeDates()}
                placeholder={`Choisissez une date`}
                onSelected={startDt => {
                  let startTime = dayjs(startDt)
                  let endTime = dayjs(startDt)
                  if (category) {
                    // Use the suggested start time to initialize the time picker
                    const suggestedHours = suggestedHoursByCategory[category]

                    // suggested hours could be empty, like in 'noel' event type, so do nothing.
                    if (suggestedHours) {
                      const { hours, minuts } = suggestedHours.suggestedStart

                      startTime = startTime.hour(hours)
                      startTime = startTime.minute(minuts)

                      endTime = endTime.hour(hours + 1)
                      endTime = endTime.minute(minuts)
                    }
                  }

                  formal.change('startDateTime', startTime.toDate())
                  formal.change('endDateTime', endTime.toDate())

                  dispatchEventCtx({
                    type: 'UPDATE_DELIVERY_DATETIME',
                    payload: {
                      startDateTime: startTime.toDate(),
                      endDateTime: endTime.toDate(),
                    },
                  })
                  submitWithDelay()
                }}
              />

              <div className="btn-bar">
                <Button
                  id="previous"
                  className="previous btn-secondary"
                  onClick={() => navigate(-1)}
                >
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledDatePage>
  )
}

const StyledDatePage = styled.div`
  .page-content {
    max-width: 500px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  ${StyledDatePicker} {
    display: inline-block;
    align-self: center;
    margin: 40px auto;
  }

  .hint {
    text-align: center;
    color: ${colors.text.light2};
  }

  .btn-secondary {
    background: transparent;
    border: 1px solid ${borders.color.light};
    box-shadow: 1px 1px 6px #00000029;
  }

  .btn-bar {
    display: flex;
    margin: 50px 0;

    ${StyledButton} {
      padding: 0 40px;
    }

    & > *:first-child {
      margin-right: auto;
      margin-left: 0;
      display: flex;
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(126, 126, 126, 0.11);
      }
      div {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .btn-bar {
      ${StyledButton} {
        padding: 0 26px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    ${StyledDatePicker} {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  .wrong {
    color: ${colors.brand.normal};
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      both;
  }
  @keyframes shake-horizontal {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      transform: translateX(-5px);
    }
    20%,
    40%,
    60% {
      transform: translateX(5px);
    }
    80% {
      transform: translateX(4px);
    }
    90% {
      transform: translateX(-4px);
    }
  }
`

export default DatePage
export { StyledDatePage }
